import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Intro from "../components/Intro"

// markup
const NotFoundPage = () => {
  return (
    <Layout title="Page not found">
      <Intro data={{ headline: "Error 404 – Page not found" }} smallHeadline>
        <Link
          to="/"
          className="inline-flex items-center justify-center w-auto h-10 px-6 font-bold transition-colors duration-300 ease-in-out border-2 border-black rounded-2xl border-opacity-80 hover:bg-black hover:text-white hover:text-opacity-95"
        >
          return to home page
        </Link>
      </Intro>
    </Layout>
  )
}

export default NotFoundPage
